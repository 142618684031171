import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';

export class ProductFamily extends TwoListsSelectModel {
  public name: string;

  override get title() {
    return this.name;
  }

  override set title(value: string) {
    this._title = value;
    this.name = value;
  }
}
