import { ProductFamily } from './product-family.model';
import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';
import { PictureModel } from '../picture.model';
import { ProductSeason } from './product-season.model';
import { Brand } from '../events/brand.model';
import { Category } from '../events/category.model';
import { ProductScanId } from './product-scan-id.model';

export class Product extends TwoListsSelectModel {
  public name: string = '';
  public imageName: string | null;
  public season: SEASON;
  public year: number;
  public brand: Brand;
  public productType: ProductTypeEnum;
  public idPlasma: string = '';
  public categoryColor: string = '';
  public categoryName: string = '';
  public categoryId: number;
  public pfName: string;
  public pfId: number;
  public scanIds: ProductScanId[] = [];
  public selectedScan: string;
  public selectedSerialNumber: string;
  public imageContent: PictureModel;
  public last: string;
  public factory: string;
  public productSeasons: ProductSeason[];
  public plasmaProduct: boolean;
  public plasmaProductPrototype: boolean;
  private _category: Category | null = null;
  private _productFamily: ProductFamily | null = null;

  override set title(value: string) {
    this._title = value;
    this.name = value;
  }

  override get title() {
    return this.name;
  }

  get category(): Category | null {
    return this._category;
  }

  set category(category: Category) {
    this._category = Object.assign(new Category(), category);
    if (category && category.id) {
      this.categoryName = category.name;
      this.categoryColor = category.color;
      this.categoryId = category.id;
    }
  }
  get productFamily(): ProductFamily | null {
    return this._productFamily;
  }

  set productFamily(pf: ProductFamily) {
    this._productFamily = Object.assign(new ProductFamily(), pf);
    if (pf && pf.id) {
      this.pfName = pf.name;
      this.pfId = pf.id;
    }
  }

  override get keepUnchoosen(): boolean {
    return this.plasmaProduct || this.plasmaProductPrototype;
  }
}

export enum ProductTypeEnum {
  RangeProduct = 'Range Product',
  Competitor = 'Competitor',
  Prototype = 'Prototype',
  Race = 'Race',
}

export enum SEASON {
  FW = 'FW',
  SS = 'SS',
  WS = 'WS',
}
