import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Event } from '../../models/events/event.model';
import { FiltersModel } from '../../models/paging/filters.model';
import { Page } from '../../models/paging/page';
import { DefaultPageable, PageableModel } from '../../models/paging/pageable-model';
import { PictureModel } from '../../models/picture.model';
import { ProductHistory } from '../../models/products/product-history.model';
import { ProductType } from '../../models/products/product-type.model';
import { Product } from '../../models/products/product.model';
import { ProductInterface } from './product.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements ProductInterface {
  constructor(private httpClient: HttpClient) {}

  verifyScanId(id: string, event: Event): Observable<Product> {
    throw new Error('Method not implemented.');
  }

  /**
   * Get a all products
   *
   * @returns
   */
  getAllProducts(filters?: FiltersModel, pageable?: PageableModel): Observable<Page<Product>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();

    let params;
    if (filters) {
      const generalFilters = filters.getModelExcludeProperties(['plasmaProductDropped']);
      const generalParamFilters = generalFilters.toParamsWithProperty();
      const plasmaDroppedFilters = filters.getModelIncludeProperties(['plasmaProductDropped']);
      const plasmaDroppedParamFilters = plasmaDroppedFilters.toParams('droppedProduct');
      params = [paramsPage, generalParamFilters, plasmaDroppedParamFilters].filter((p) => p != null).join('&');
    } else {
      params = [paramsPage].filter((p) => p != null).join('&');
    }

    return this.httpClient.get<Page<Product>>(environment.apiUrl + `/products?${params}`);
  }

  /**
   * Get a product from an id
   *
   * @returns
   */
  getProduct(id: number): Observable<Product> {
    return this.httpClient.get<Product>(environment.apiUrl + `/products/${id}`);
  }

  /**
   * Add a product
   *
   * @param product
   * @returns
   */
  addProduct(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(environment.apiUrl + '/products', { ...product, title: product.title });
  }

  /**
   * Update a product
   *
   * @param product
   * @returns
   */
  updateProduct(product: Product): Observable<Product> {
    return this.httpClient.put<Product>(environment.apiUrl + '/products', { ...product, title: product.title });
  }

  /**
   * Delete a product
   *
   * @param product
   * @returns
   */
  deleteProduct(product: Product): Observable<void> {
    return this.httpClient.delete<void>(environment.apiUrl + `/products/${product.id}`);
  }

  setProductPicture(productId: number, content: PictureModel): Observable<PictureModel> {
    return this.httpClient.post<PictureModel>(environment.apiUrl + `/picture/product/${productId}`, content);
  }

  updateProductPicture(productId: number, content: PictureModel): Observable<PictureModel> {
    return this.httpClient.put<PictureModel>(environment.apiUrl + `/picture/product/${productId}`, content);
  }

  getProductPicture(productId: number): Observable<PictureModel> {
    return this.httpClient.get<PictureModel>(environment.apiUrl + `/picture/product/${productId}`);
  }

  getProductPictureFromProduct(product: Product): Observable<Product> {
    return this.httpClient.get<Product>(environment.apiUrl + `/picture/product/min/${product.id}`);
  }

  getNotAffected2bagProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(environment.apiUrl + '/productsForBag');
  }

  getProductTypes(): Observable<ProductType[]> {
    return this.httpClient.get<ProductType[]>(environment.apiUrl + '/product-types');
  }

  getProductHistories(productId: number): Observable<ProductHistory[]> {
    return this.httpClient.get<ProductHistory[]>(environment.apiUrl + `/product-histories/${productId}`);
  }
}
