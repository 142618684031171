import { Sport } from '../sports/sport.model';
import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';
import { User } from '../users/user.model';
import { Brand } from './brand.model';
import { ComplementaryInfo } from './complementary-info.model';
import { ProductFamily } from '../products/product-family.model';

export class Category extends TwoListsSelectModel {
  public name: string;
  public responsibleFirstName: string = '';
  public responsibleLastName: string = '';
  public responsibleId: number;
  public productFamilies: ProductFamily[];
  public status: CategoryStatus;
  public sports: Sport[];
  public brand: Brand[] = [];
  public complementaryInfo: ComplementaryInfo[] = [];
  public color: string;
  private _responsible: User;

  get responsible(): User {
    return this._responsible;
  }

  set responsible(responsible: User) {
    this._responsible = Object.assign(new User(), responsible);
    this.responsibleFirstName = responsible.firstName;
    this.responsibleLastName = responsible.lastName;
    if (responsible.id) this.responsibleId = responsible.id;
    else console.error('null response.id');
  }

  override get title() {
    return this.name;
  }

  override set title(value: string) {
    this._title = value;
    this.name = value;
  }
}

export enum CategoryStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
